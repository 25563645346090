.usersContainer {
  height: 100%;
  width: 20% !important;
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  background-color: var(--bg-color-form);
  margin: 0 !important;
  padding: 0 !important;
  gap: 1%;
}

.usersHeader {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10%;
  color: var(--colorTwo);
}
.usersHeaderOne {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.usersSection {
  width: 100%;
  height: 62%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5%;
}

.dashboardHeader {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title-h1 {
  color: var(--primary-color-h1);
  margin: 0;
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.dashboardHeaderDiv p {
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  margin: 0 0 0 10px;
  color: var(--primary-color-card);
}

.dialogContent {
  background: var(--bg-color-form) !important;
}
.dialogContent .modal-header,
.dialogContent .modal-body,
.dialogContent .modal-footer {
  border-color: black !important;
}

.dialogContent .modal-body {
  margin: 25px 0;
}

.dashboardSection {
  width: 100%;
  height: 50%;
  display: flex;
  gap: 2%;
}
.projectSection {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
}

.dashboardSection .apexChart {
  width: 70%;
  height: 100%;
  background-color: var(--primary-color);
  border-radius: 45px;
}
.dashboardSection .sideTwoSection {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2%;
}
.dashboardSection .sideTwoSection .sideTwoSectionOne {
  width: 100%;
  height: 30%;
  background-color: var(--primary-color-card);
  color: var(--primary-color-card);
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3%;
}
.dashboardSection .sideTwoSection .sideTwoSectionOne span {
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  border-radius: 45px;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  gap: 10%;
}
.dashboardSection .sideTwoSectionOne .icon,
.dashboardSection .sideTwoSection .sideTwoSectionTwo .icon {
  width: 75px;
  height: 75px;
  color: var(--colorTwo);
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
.dashboardSection .sideTwoSection .sideTwoSectionTwo .icon {
  color: var(--colorTwo) !important;
}
.btn-front {
  color: var(--colorTwo);
}

.btn-front .iconPf {
  color: var(--primary-color-card) !important;
}

.dashboardHeaderDiv {
  width: max-content;
  height: max-content;
  background: var(--bg-color-form);
  border-radius: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 15px 0 0;
  border: 15px solid var(--chakra-colors-gray-400);
}

.dashboardSection .sideTwoSectionOne .stat {
  width: 100%;
  height: 100%;
}
.dashboardSection .sideTwoSection .sideTwoSectionTwo {
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  border-radius: 44px;
  color: var(--colorTwo) !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10%;
}
.dashboardSection .sideTwoSection .sideTwoSectionTwo .top {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15%;
  padding-left: 30px;
  background-color: var(--primary-color-card);
  border-radius: 45px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: var(--colorTwo);
}
.dashboardSection .sideTwoSection .sideTwoSectionTwo .bottom {
  width: 100%;
  height: 60%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.dashboardSection .sideTwoSection .sideTwoSectionTwo .bottom .container {
  color: var(--colorTwo);
  z-index: 1;
}
.apexChart .apexChartHeader {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  padding: 0 5%;
}
.usersSection .tabsMDB,
.usersSection .tabsMDBContent {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #0f0f0f75;
  border-radius: 20px;
  padding: 2% 0;
  margin: 0 0 16px 0 !important;
}

.usersSection .tabsMDB {
  width: max-content;
  padding: 2%;
}

.usersSection .tabsMDBContent {
  height: 100%;
}
.listGroup {
  width: 100%;
  max-width: 100%;
  height: max-content;
  gap: 5px;
  padding: 5%;
}

.tabBtn {
  width: max-content !important;
  height: max-content !important;
  border-radius: 50px !important;
  background-color: var(--primary-color) !important;
  border: 0 !important;
  border-color: transparent !important;
  color: var(--colorTwo) !important;
}
.tabBtnChart {
  border-radius: 50px !important;
  background-color: var(--primary-color-card) !important;
  color: var(--colorTwo) !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 30px;
}

.tabBtnChartItem {
  color: var(--colorTwo);
  background-color: black !important;
  display: flex;
  justify-content: center;
}

.tabBtn.active {
  background-color: var(--primary-color-card) !important;
  color: var(--colorTwo) !important;
}
.usersFooter {
  width: 100%;
  height: 20%;
}

.disconnectGroupItem {
  width: 100%;
  height: 100%;
  padding: 2%;
}

.disconnectGroupItem .allDisconnect {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--colorTwo);
}

/* ============================================= */

.block-container {
  position: relative;
  width: 85px;
  height: 85px;
  margin: 0;
  transition: 250ms;
  perspective: 500px;

  .btn-back {
    position: absolute;
    inset: 0;
    z-index: -1;
    width: inherit;
    height: inherit;
    border-radius: 32px;
    transition: 250ms;
    transform-style: preserve-3d;
    transform-origin: bottom right;
    transform: rotateZ(15deg);
    will-change: transform;
    box-shadow: 16px 0 40px #e4e4e4;
  }

  .btn-front {
    position: absolute;
    inset: 0;
    z-index: 1;
    width: inherit;
    height: inherit;
    border-radius: 32px;
    background-color: #ffffff33;
    backdrop-filter: blur(20px);
    transition: 250ms;
    transform-style: preserve-3d;
    transform-origin: top left;
    overflow: hidden;

    svg.icon {
      position: absolute;
      inset: 50% 0 0 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.amount {
  color: var(--colorTwo);
}

/* =============================================== */

.section-bubble1 {
  position: absolute;
  width: 100%;
  bottom: 0;
}

/* Section 1 */
.section-bubble1 {
  background-color: var(--primary-color-card);
  position: relative;
  background-repeat: no-repeat;
}

.section-bubble1 svg {
  position: absolute;
  top: 0;
}



.dropend .dropdown-toggle::after {
  display: none !important;
}

.dropdown-toggle {
  display: flex;
  width: max-content;
}

/* Test.css */

.post-card.creative {
  background: linear-gradient(135deg, #171717, #000000);
  color: #c9d1d9;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
  transition: transform 0.3s, box-shadow 0.3s;
}

.post-card.creative:hover {
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3);
}

.post-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.post-client {
  display: flex;
  flex-direction: column;
}

.post-client-name {
  font-weight: 700;
  font-size: medium;
  color: #f0f6fc;
}

.post-date {
  font-size: small;
  color: #8b949e;
  font-family: "IBM Plex Sans Condensed", sans-serif;
  font-weight: 400;
  width: max-content;
  height: max-content;
  font-style: normal;
}

.post-options {
  font-size: 24px;
  cursor: pointer;
  color: #8b949e;
  transition: color 0.3s;
}

.post-options:hover {
  color: #f0f6fc;
}

.post-content {
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #e6edf3;
}

.post-footer {
  position: relative;
  border-top: 1px solid #21262d;
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: small;
  gap: 12px;
}

.post-footer-info {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #8b949e;
}

.post-status {
  font-weight: 600;
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 14px;
}

.post-status.approved {
  background-color: #238636;
  color: #ffffff;
}

.post-status.pending {
  background-color: #d29922;
  color: #ffffff;
}

.post-status.rejected {
  background-color: #da3633;
  color: #ffffff;
}

.post-footer-actions {
  display: flex;
  gap: 12px;
}

.post-action-button {
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  color: #ffffff;
}

.post-action-button:hover {
  transform: translateY(-3px);
}

.post-action-button.edit {
  background-color: #0969da;
}

.post-action-button.delete {
  background-color: #b62324;
}

.post-action-button.report {
  background-color: #9e6a03;
}

.post-action-button.report.reported {
  background-color: #484f58;
  cursor: not-allowed;
}

.post-social-actions {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.social-action {
  display: flex;
  align-items: center;
  gap: 6px;
}

.social-count {
  font-size: 14px;
  color: #8b949e;
}

.notification-card,
.approved-project-card,
.programmer-request {
  background: linear-gradient(135deg, #171717, #000000);
  color: #fff;
  width: 100%;
  height: max-content;
  padding: 5%;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 15px;
  transition: 0.3s ease-in-out;
}
.approved-project-card {
  background: linear-gradient(135deg, #000000, #171717);
  height: 90%;
}

.programmer-request {
  padding: 0;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-around;
  padding: 2% 5% 2% 0;
}

.approved-project-card .approved-project-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.approved-project-header .approved-project-ubject {
  font-size: medium;
  color: var(--colorTwo73);
  font-family: "IBM Plex Sans Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.notification-card:hover {
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3);
}

.notification-header,
.notification-footer {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-header .notification-name {
  font-size: medium;
}

.notification-footer .notification-timing {
  font-size: small;
  color: var(--colorTwo73);
}

.notification-content {
  display: flex;
  flex-direction: column;
}

.notification-title {
  font-weight: bold;
  font-size: 16px;
}

.notification-text {
  font-size: 14px;
  color: #ccc;
  margin: 5px 0;
}

.notification-time {
  font-size: 12px;
  color: #777;
}

.notification-actions {
  display: flex;
  gap: 10px;
}

.mark-read-btn {
  background-color: #222;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.view-btn:hover,
.mark-read-btn:hover {
  background-color: #333;
}

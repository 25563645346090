

.was-validated .input-group .invalid-feedback, .was-validated .input-group .valid-feedback {
  margin-top: 0.1rem !important;
}

.profileDiv {
  width: 100%;
  max-height: 100vh;
  height: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;
}

.profileDiv .profileContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.profileDiv .profileContainer .profileCard {
  width: 100%;
  height: 100%;
  background: var(--primary-color-card);
  padding-top: 2%;
  border-radius: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 2%;
  overflow: auto;
}

.profileDiv .profileContainer .profileCard .profileHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5%;
  border: none;
}

.section-2 {
  height: 100%;
}

.section-2 .usersSection {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 0;
}

.tabsMDB.profileTabsMDB {
  height: max-content;
  padding: 1% 2%;
  width: 100%;
}
.tabsMDB.profileTabsMDB .tabsMDBContent {
  width: 100%;
  height: 40vh !important;
}

.profileHeader .profilePic,
.profileHeader .profilePicRating {
  background-color: var(--bg-color-form);
  color: var(--primary-color-card);
}
.profileHeader .profilePicRating::after {
  content: "\2605 3.5";
  position: absolute;
  right: 0;
  bottom: 5%;
  font-size: 14px;
  padding: 0 10px;
  border-radius: 4px;
  background-color: #6a7f05;
  color: var(--bg-color-form);
}

#formControlDisabled {
  color: var(--colorTwo) !important;
}

.form-label {
  color: var(--colorTwo) !important;
}

.profileHeader .profileFigure .profileUserName {
  color: var(--colorTwo);
  text-transform: uppercase;
  margin: 0 !important;
  padding: 0 !important;
}

.profileHeader .profileFigure .profileUserName p {
  margin: 0 !important;
}

.profileHeader .profileFigure .profileUserMail {
  text-align: center;
}

.profileDiv .profileContainer .profileCard .profileBody {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 1% 5%;
}

.profileBody .section-1 {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: space-around;
  background-color: var(--bg-color-form);
  border-radius: 10px;
  padding: 2% 0;
}

.section-1 .sideTwoSectionOne {
  width: 100%;
}
.section-1 .sideTwoSectionOne span {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2%;
}

.btn-front .icon {
  color: var(--colorOne);
  width: 75px;
  height: 75px;
  color: var(--colorOne);
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.profilePageContainer {
  width: 100%;
  height: 100%;
  background-color: var(--bg-color-form);
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.profilePageContainer .profilePageSpan-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 94%;
  background-color: var(--primary-color-card);
  border-radius: 10px;
  padding: 1%;
  color: var(--colorTwo);
}

.spanInputsProfile {
  display: flex;
  width: 80%;
  gap: 5%;
  color: var(--colorTwo);
}

.paneProfile {
  width: 100%;
  padding: 0 2%;
}










/* ================================    edit     ================================= */

.edit-profile-div {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
  margin: 1% 0;
  padding: 0 3%;
  color: var(--colorTwo);
}


.edit-profile-div .side-edit-1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.edit-profile-div .side-edit-2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.edit-profile-div .side-edit-2 .spanInputsProfile {
  width: 100%;
}


.css-p27qcy:focus-visible, .css-p27qcy[data-focus-visible] {
  box-shadow: none !important;
}


.clearfix::after { 
  content: "";
  clear: both;
  display: table;
}

div.search-bar {
  position: relative;
  margin-top: 15px;
  text-align: center;
  z-index: 1;
}

.editableDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  gap: 5px;
}
.editableDiv .col-md-6 {
  width: 100%;
}
.editableDiv .form-outline {
  width: 100% !important;
}
.leadercheck {
  width: 30px;
  height: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

div.search-bar .ip-search {
  width: 85%;
  border: none;
  font-size: 16px;
  padding: 10px 15px;
  color: rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.25);
  border-radius: 50px;
  border: 1px solid rgba(60, 51, 176, 0.7);
  box-shadow: inset 0 25px 25px -15px rgba(60, 51, 176, 0.15);
}

div.search-bar .ip-search::placeholder {
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 1px;
  font-size: 14px;
}

div.search-bar i.fa {
  position: absolute;
  right: 0;
  margin-right: 25px;
  margin-top: 14px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
}

.friend-list {
  margin-top: 35px;
}

.friend-list ul li {
  position: relative;
  list-style: none;
  float: left;
  width: 20%;
  text-align: center;
}

.friend-list ul li img {
  border-radius: 100%;
  border: 3px solid rgba(255, 255, 255, 1);
}

.friend-list ul li:nth-child(2n + 1) img {
  width: 40px;
  height: 40px;
}

.friend-list ul li:nth-child(2n + 2) img {
  width: 50px;
  height: 50px;
  transform: translateY(-10%);
}

.friend-list ul li:nth-child(3) img {
  width: 70px;
  height: 70px;
  transform: translateY(-20%);
}

.friend-list ul li:nth-child(2n + 1) img,
.friend-list ul li:nth-child(2n + 1) span.name {
  opacity: 0.4;
}

.friend-list ul li:nth-child(2n + 2) img,
.friend-list ul li:nth-child(2n + 2) span.name {
  opacity: 0.7;
}

.friend-list ul li.active img,
.friend-list ul li.active span.name {
  opacity: 1;
}

.friend-list ul li.active img {
  box-shadow: 1px 1px 0 rgba(39, 28, 93, 0.5), -1px -1px 0 rgba(39, 28, 93, 0.5),
    0 0 35px rgba(255, 255, 255, 0.3);
}

.friend-list ul li span.name {
  position: relative;
  width: 100%;
  left: 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.9);
}

.friend-list ul li.active span.name {
  top: -7px;
}

.friend-list ul li span.name span.status {
  display: block;
  text-transform: none;
  font-weight: 300;
  font-size: 8px;
  color: rgba(255, 255, 255, 0.5);
}

.friend-list ul li.active span.name span.status {
  font-size: 9px;
}

.friend-list ul li:nth-child(2n + 1) span.messages span.count {
  position: absolute;
  font-size: 10px;
  width: 17px;
  height: 17px;
  display: inline-block;
  background: #f42052;
  z-index: 10;
  line-height: 17px;
  border-radius: 100%;
  margin-top: -2px;
  right: 0;
  margin-right: 10px;
  color: #fff;
}

.friend-list ul li:nth-child(2n + 2) span.messages span.count {
  position: absolute;
  z-index: 10;
  font-size: 10px;
  width: 20px;
  height: 20px;
  display: inline-block;
  background: #f42052;
  line-height: 20px;
  border-radius: 100%;
  margin-top: -10px;
  right: 0;
  margin-right: 7px;
  color: #fff;
}

.friend-list ul li.active span.messages,
.friend-list ul li span.messages.hide {
  display: none;
}

div.chat-messages {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
  background-color: var(--primary-color-s);
}

.chat-details {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-last-message {
  display: flex;
  justify-content: space-between;
  color: var(--colorTwo73) !important;
}

.chat-name {
  font-size: small;
  width: max-content;
}

.chat-last-message span,
.chat-last-message div {
  font-size: x-small;
}

div.chat {
  position: relative;
  width: 100%;
  background-color: #1f1f1f4d;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
}

div.chat div.chat-content {
  height: max-content;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;
  font-size: x-small;
}
*::-webkit-scrollbar {
  width: 12px;
}
div.chat div.chat-content .deleted-conversation-header,
.date-conversation-split {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.chat div.chat-content .deleted-conversation-header p,
.date-conversation-split p {
  background-color: var(--primary-color);
  color: var(--colorTwo);
  padding: 1% 2%;
  border-radius: 5px;
  opacity: 0.5;
  font-size: xx-small;
}
div.chat div.chat-content span.you {
  align-self: flex-end;
}

div.chat div.chat-content span.friend {
  align-self: flex-start;
}

div.chat div.chat-content > span {
  margin-bottom: 12px;
}

div.chat div.chat-content span.friend {
  position: relative;
  width: max-content;
  min-width: 100px;
  height: auto;
  display: inline-block;
  background-color: var(--friendChat);
  padding: 10px;
  padding-bottom: 25px;
  box-shadow: 2px 2px 20px -2px rgba(102, 102, 102, 0.2);
  color: var(--colorTwo);
}

div.chat div.chat-content span.friend.first {
  border-radius: 15px 15px 15px 2px;
}
div.chat div.chat-content span.friend.middle {
  border-radius: 15px;
}

div.chat div.chat-content span.friend.last {
  border-radius: 2px 15px 15px 15px;
}

span.typing {
  position: relative;
  width: max-content;
  min-width: 100px;
  height: max-content;
  min-height: 50px;
  display: inline-block;
  background: var(--friendChat);
  margin-bottom: 12px;
  padding: 10px !important;
  text-align: center;
  padding-bottom: 25px;
  box-shadow: 2px 2px 20px -2px rgba(80, 192, 104, 0.2);
  color: var(--primary-color);
  border-radius: 2px 15px 15px 15px;
}

div.chat div.chat-content span.friend span.time {
  position: absolute;
  display: flex;
  right: 0;
  margin-top: 5px;
  margin-right: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  font-size: xx-small;
}

div.chat div.chat-content span.you {
  position: relative;
  right: 0;
  display: block;
  float: right;
  width: max-content;
  min-width: 100px;
  height: auto;
  background: var(--primary-color);
  padding: 10px;
  padding-bottom: 25px;
  color: var(--colorTwo);

  box-shadow: 2px 2px 20px -2px rgba(102, 102, 102, 0.2);
}

div.chat div.chat-content span.you span.time {
  position: absolute;
  display: flex;
  gap: 5px;
  right: 0;
  margin-top: 5px;
  margin-right: 10px;
  font-size: xx-small;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
}

div.chat div.chat-content span.you.first {
  border-radius: 15px 15px 2px 15px;
}

div.chat div.chat-content span.you.middle {
  border-radius: 15px;
}

div.chat div.chat-content span.you.last {
  border-radius: 15px 2px 15px 15px;
}

div.msg-box {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-top: 1px solid rgba(60, 51, 176, 0.1);
  overflow: hidden;
}

div.msg-box .ip-msg {
  width: 70%;
  font-size: 14px;
  padding: 15px;
  padding-right: 30%;
  color: rgba(60, 51, 176, 0.9);
  border: none;
  background: rgba(0, 0, 0, 0.03);
}

div.msg-box .ip-msg::placeholder {
  color: rgba(60, 51, 176, 0.4);
}

div.msg-box span.btn-group {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 14px;
  display: inline-block;
  margin-right: 10px;
}

div.msg-box span.btn-group i {
  color: rgba(60, 51, 176, 0.5);
  font-size: 16px;
}

div.msg-box span.btn-group i:hover {
  color: var(--primary-color);
}

















.details-chat-header {
  display: flex;
  width: 100%;
  height: 10%;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
}

.details-chat-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70%;
  padding: 10px;
  gap: 12px; /* Add spacing between items */
}

.details-chat-footer {
  display: flex;
  width: 100%;
  height: 20%;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
}

.details-project-subject {
  font-size: small;
  color: var(--colorTwo);
  font-weight: bold; /* Make subject bold for emphasis */
}

.details-project-body {
  font-size: x-small;
  display: flex;
  flex-direction: column;
  color: var(--colorTwo73);
  padding: 5px 0; /* Add padding for spacing */
}

.notificationsDivider {
  margin: 10px 0; /* Add spacing around dividers */
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

:root {
  /* color of the active and inactive buttons... */
  --primary-color: #000000;
  --primary-color-s: #0000004d;

  /* background color of header footer and cards on the section */
  --primary-color-card: #303030;
  --primary-color-card-s: #1e233e4d;

  /* background of the body section */
  --secondary-color: #0c0c0c;
  --primary-bg: #2b2b2b;

  /* color of titles , writing ... */
  --colorTwo: #ffffff;
  --bg-color-form: #fff;
  --colorTwo73: #ffffff73;

  /* background of the friend chat */
  --friendChat: #00000054;
  --headerChat: #161616;

  /* do not change this */
  --colorHover: rgba(0, 0, 0, 0.204);
  --colorActive: rgba(0, 0, 0, 0.337);

  --primary-color-h1: #fff;
}
/* :root {

  --primary-color: #646464;
  --primary-color-s: #3737374d;

  --primary-color-card: #1c1c1c;
  --primary-color-card-s: #1e233e4d;

  --secondary-color: #000000;
  --primary-bg: #1b1b1b;

  --colorTwo: #ffffff;
  --bg-color-form: #848484;
  --colorTwo73: #ffffff73;

  --friendChat: #00000054;

  --colorHover: rgba(0, 0, 0, 0.204);
  --colorActive: rgba(0, 0, 0, 0.337);
  --primary-color-h1: #fff;
} */

.all-things-here {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.6%;
  /* padding: 0 0 0 1%; */
  height: 100%;
  width: 100%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

#root,
.soon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  position: relative;
  gap: 1%;
  padding-right: 0;
  width: 100vw;
  overflow: auto;
}
#root {
  background: linear-gradient(
    135deg,
    var(--primary-bg),
    var(--secondary-color)
  );
}

.soon-container {
  background: black;
}

.container-LR {
  background-color: var(--bg-color-form);
  border-radius: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  position: relative;
  overflow: hidden;
  width: 75%;
  max-width: 100%;
  height: 70%;
  min-height: 480px;
}

.container-LR p {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  margin: 20px 0;
}

.container-LR span {
  font-size: 12px;
}

.container-LR #remember {
  width: max-content;
  height: max-content;
}

.container-LR a,
.container-LR label {
  color: #333;
  font-size: x-small;
  text-wrap: nowrap;
  text-decoration: none;
  margin: 10px 0 10px 0;
}

.container-LR .last-side {
  height: max-content;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.container-LR .last-side div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.container-LR button {
  background-color: var(--primary-color);
  color: var(--colorTwo);
  font-size: 12px;
  padding: 10px 45px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-top: 10px;
  cursor: pointer;
}

.container-LR button.hidden {
  background-color: transparent;
  border-color: var(--colorTwo);
}

.container-LR form {
  background-color: var(--bg-color-form);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  height: 100%;
}

.container-LR input {
  background-color: #eee !important;
  border: none !important;
  margin: 8px 0 !important;
  padding: 10px 15px !important;
  font-size: small !important;
  border-radius: 8px !important;
  width: 100% !important;
  outline: none !important;
}

.form-container-LR {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container-LR.active .sign-in {
  transform: translateX(100%);
}

.sign-up {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container-LR.active .sign-up {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: move 0.6s;
}

@keyframes move {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }
  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.social-icons {
  margin: 20px 0;
}

.social-icons span,
.social-icons button {
  border: 1px solid #ccc;
  border-radius: 20%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
  width: 40px;
  height: 40px;
}

.social-icons span.icon.disabled {
  cursor: not-allowed;
}
.social-icons span.icon.enabled {
  cursor: pointer;
}

.toggle-container-LR {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
  border-radius: 150px 0 0 100px;
  z-index: 5;
}

.container-LR.active .toggle-container-LR {
  transform: translateX(-100%);
  border-radius: 0 150px 100px 0;
}

.toggle {
  /* background-color: var(--primary-color); */
  height: 100%;
  /* background: linear-gradient(
    to right,
    var(--primary-color-card),
    var(--primary-color)
  ); */
  background: linear-gradient(135deg, #171717, #000000);
  color: var(--colorTwo);
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}

.container-LR.active .toggle {
  transform: translateX(50%);
}

.toggle-panel {
  position: absolute;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 30px;
  text-align: center;
  top: 0;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}

.toggle-left {
  transform: translateX(-200%);
}

.container-LR.active .toggle-left {
  transform: translateX(0);
}

.toggle-right {
  right: 0;
  transform: translateX(0);
}

.container-LR.active .toggle-right {
  transform: translateX(200%);
}

.parent-home {
  width: 100vw;
  height: 100vh;
  display: grid;
  overflow: auto;
  padding: 0;
}

/* General animations and transitions */
.homepage-container {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
}

.homepage-header {
  background-color: var(--primary-color-card);
  color: var(--colorTwo);
  text-align: center;
  padding: 40px 20px;
  font-size: 3rem;
  font-weight: 700;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow-light);
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 1s forwards 0.3s;
}

/* Scroll effect: each section fades in */
.homepage-main {
  background-color: var(--primary-bg);
  width: 100%;
  display: grid;
}

.homepage-section {
  padding: 60px 20px;
  height: 100vh;
  color: var(--colorTwo);
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  transition: background-color 0.4s ease;
  box-shadow: var(--shadow-light);
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.homepage-section:nth-child(even) {
  background-color: var(--primary-color-card-s);
}

.homepage-button {
  background-color: var(--friendChat);
  color: var(--colorTwo);
  padding: 15px 40px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease-in-out;
}

.homepage-div-span {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: max-content;
}

.homepage-span {
  position: relative;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  font-family: "IBM Plex Sans Condensed", sans-serif;
  font-weight: 100;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}
.homepage-span-bottom {
  border-radius: 8px;
  font-size: 0.5rem;
  cursor: pointer;
  font-family: "IBM Plex Sans Condensed", sans-serif;
  font-weight: 100;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  position: absolute;
  opacity: 0;
  top: 150%;
}

.homepage-div-span:hover .homepage-span-bottom {
  position: absolute;
  top: 100%;
  opacity: 1;
  transition: all 0.4s 0.3s ease-in-out;
}
.homepage-span::before,
.homepage-span::after {
  content: "";
  position: absolute;
  top: -5%;
  height: 100%;
  width: 2px;
  border-radius: 10px;
  background-color: var(--colorTwo);
  transition: all 0.3s ease-in-out;
  opacity: 0;
}
.homepage-span::before {
  left: -5%;
}
.homepage-span::after {
  right: -5%;
}
.homepage-div-span:hover .homepage-span::before,
.homepage-div-span:hover .homepage-span::after {
  height: 200%;
  opacity: 1;
}


.homepage-button:hover {
  background-color: var(--primary-color-s);
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25);
}

/* Media Queries */
@media (max-width: 768px) {
  .homepage-header {
    font-size: 2.2rem;
  }

  .homepage-section h2 {
    font-size: 2rem;
  }

  .homepage-section p,
  .homepage-section ul {
    font-size: 1rem;
  }

  .homepage-button {
    padding: 12px 30px;
    font-size: 1rem;
  }
}

/* Keyframe animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

#landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 0 20px;
  height: 90vh;
  width: 100vw;
}

#landing-page .platform-description {
  max-width: 800px;
  margin-top: 40px;
}

#landing-page h2 {
  color: hsl(0, 0%, 28%);
  font-size: 36px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

#landing-page p {
  color: hsl(0, 0%, 45%);
  font-size: 18px;
  line-height: 1.6;
  font-family: "IBM Plex Sans Condensed", sans-serif;
  font-weight: 300;
}

#landing-page ul {
  text-align: left;
  color: hsl(0, 0%, 45%);
  font-size: 18px;
  line-height: 1.8;
}

#landing-page ul li {
  margin-bottom: 10px;
  font-family: "IBM Plex Sans Condensed", sans-serif;
  font-weight: 300;
}

#landing-page .launch-message {
  margin-top: 20px;
  font-style: italic;
  font-family: "IBM Plex Sans Condensed", sans-serif;
  font-weight: 100;
  color: hsl(0, 0%, 50%);
}

h1.pc-message {
  color: hsl(0, 0%, 28%);
  font-size: 50px !important;
  letter-spacing: 7px !important;
  font-family: "IBM Plex Sans Condensed", sans-serif;
  font-weight: 100;
  font-style: normal;
  cursor: pointer;
  text-transform: uppercase;
}

h1.pc-message {
  padding: 64px;
  background: linear-gradient(
    to right,
    hsl(0, 0%, 30%) 0,
    hsl(0, 0%, 100%) 10%,
    hsl(0, 0%, 30%) 20%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 6s infinite linear;
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  40% {
    background-position: 200px;
  }
  50% {
    background-position: 200px;
  }
  90% {
    background-position: 0;
  }
}




/* ========================= */



.homepage-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--colorTwo);
  margin-bottom: 10px;
}

.homepage-description {
  font-size: 1rem;
  color: var(--colorTwo73);
  margin-bottom: 20px;
}



@media (max-width: 768px) {
  .homepage-div-span {
    padding: 10px 20px;
  }

  .homepage-title {
    font-size: 2rem;
  }

  .homepage-description {
    font-size: 0.9rem;
  }

  .homepage-span {
    font-size: 1.1rem;
  }

  .homepage-span-bottom {
    font-size: 0.8rem;
  }

  .homepage-section {
    padding: 30px 15px;
  }
}






h1.homepage-h1 {
  margin: 0; padding: 0;
  position:absolute;
  top: 50%; left: 50%;
  transform:translate(-50%,-50%);
  font-size: 6em; color:transparent;
  text-transform:uppercase;
  cursor: pointer;
}
h1.homepage-h1 span:nth-child(1){
  position:absolute;
  top:0; left:0; color: var(--colorTwo);
  transition:0.5s;
  clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
  overflow:hidden;
}
h1.homepage-h1:hover span:nth-child(1){
  transform:translateY(-18px); 
}
h1.homepage-h1 span:nth-child(2){
  position:absolute;
  top:0; left:0; color: var(--colorTwo);
  transition:0.5s;
  clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
  overflow:hidden;
}
h1.homepage-h1:hover span:nth-child(2){
  transform:translateY(18px); 
}
h1.homepage-h1 span:nth-child(3){
  position:absolute;
  top:50%; left: 0;
  transform:translateY(-50%) scaleY(0);
  width: 97%; color: var(--primary-color);
  background:var(--colorTwo);
  font-size: 0.2em; font-weight: 500;
  letter-spacing:0.7em;
  text-align:center;
  padding-left: 20px; margin-left: 5px;
  transition:0.5s;
}
h1.homepage-h1:hover span:nth-child(3){
  transform:translateY(-50%) scaleY(1);
}
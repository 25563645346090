.reportCol {
    margin: 0;
  }
  
  .reportContainer {
    width: 100%;
    padding: 5% 5%;
    background: rgba(15, 15, 15, 0.46);
    border-radius: 20px;
    display: flex;
    align-items: center;
  }
  
  .reportContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .reportDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 2%;
    gap: 15px;
  }
  
  .reportHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .reportText {
    font-size: 10px;
    color: var(--colorTwo);
  }
  
  .fw-bold {
    font-weight: bold;
  }
  
  .text-muted {
    color: #6c757d;
  }
  
  .mb-0 {
    margin-bottom: 0;
  }
  
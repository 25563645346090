.notificationLeaderCol {
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 5%;
}

.notificationLeaderContainer {
  display: flex;
  align-items: center;
  width: 100%;
  background: #0f0f0f75;
  color: var(--colorTwo);
  border-radius: 20px;
  font-size: 12px;
}

.notificationLeaderContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10% 5%;
  gap: 10px;
}

.notificationLeaderHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 1%;
}

.notificationLeaderInfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.notificationsSection .notificationLeaderText {
  margin: 0 !important;
}


.notificationLeaderBtn {
  font-size: 12px;
}
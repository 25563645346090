.typing.friend.last {
  width: 5em;
  height: 2em;
  position: relative;
  padding: 10px;
  margin-left: 5px;
  border-radius: 20px;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.5s ease-in-out; /* Smooth transition */
}

.typing.friend.last.active {
  opacity: 1; /* Visible when active */
}

.typing__dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: #8d8c91;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

.paginateProjects {
  display: flex;
  justify-content: center;
  gap: 5%;
}

.numberPagItem {
  width: 50px;
}

.numberPagItem .page-link,
.numberPagItem .page-link:focus {
  background-color: var(--primary-color);
  color: var(--colorTwo);
}
.numberPagItem .page-link:hover,
.nextItem .page-link:hover,
.previousItem .page-link:hover {
  background-color: var(--friendChat);
  color: var(--colorTwo);
}

.numberPagItem.active .page-link {
  background-color: var(--bg-color-form) !important;
  color: var(--primary-color) !important;
}


.nextItem .page-link,
.previousItem .page-link,
.nextItem .page-link:focus,
.previousItem .page-link:focus {
  background-color: var(--bg-color-form);
  color: var(--primary-color);
}

.nextItem.disabled .page-link,
.previousItem.disabled .page-link {
  background-color: var(--primary-color) !important;
  color: var(--colorTwo) !important;
}

.nextItem.disabled,
.previousItem.disabled {
  cursor: not-allowed !important;
}

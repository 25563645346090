body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw !important;
  height: 100vh !important;
  display: flex !important;
  align-items: center !important;
  background: linear-gradient(to right, var(--primary-bg), var(--secondary-color)) !important;
  justify-content: flex-end !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.UnauthDiv,
.NFDiv,
.NLIDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  color: #ecdfcc;
  background-color: #1e201e;
}

.NoProjectsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 100%;
  border-radius: 20px;
  color: #ecdfcc;
  background-color: #1e201e;
}
.NoProjectsDivProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  color: #ecdfcc;
}
.NoNotificationsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  color: #ecdfcc;
  background-color: #1e201e;
  text-align: center;
}
.NoNotificationsDiv .blockquote-footer {
  text-align: center;
}

.UnauthDiv::before,
.NFDiv::before,
.NLIDiv::before {
  position: absolute;
  font-size: 500px;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #ecdfcc36;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.UnauthDiv::before {
  content: "403";
}
.NFDiv::before {
  content: "404";
}
.NLIDiv::before {
  content: "404";
}

.UnauthDiv .btn {
  cursor: pointer;
}
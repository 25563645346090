/* Base styles for the profile card */
.profileCardAllUser {
  background-color: var(--primary-color-card) !important;
  color: var(--colorTwo);
  cursor: pointer;
  font-size: 12px;
  transition: transform 0.3s ease;
}

.profileCardAllUser:hover {
  transform: scale(1.02);
}

.notYet {
  color: #DC4C64;
}

.profileBodyAllUser {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

/* Avatar styling */
.avatarAllUser {
  margin: 0;
  padding: 0;
  border-radius: 10px !important;
  background: #A0AEC0!important;
  color: var(--primary-color-card) !important;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .profileBodyAllUser {
    flex-direction: column;
    text-align: center;
  }

  .avatarAllUser {
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .projectSection {
    padding: 10px;
  }

  .profileBodyAllUser {
    flex-direction: row;
    justify-content: center;
  }

  .avatarAllUser {
    margin-right: 10px;
  }

  .profileCardAllUser {
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .profileCardAllUser {
    margin-bottom: 20px;
  }

  .profileBodyAllUser {
    flex-direction: column;
    justify-content: center;
  }

  .avatarAllUser {
    margin-bottom: 10px;
    margin-right: 0;
  }
}

/* Container */
.notificationsContainer {
  height: 100%;
  width: 20% !important;
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  color: var(--colorTwo);
  /* background-color: var(--primary-color-card); */
  background: linear-gradient(135deg, #171717, #000000);
  margin: 0 !important;
  padding: 0 !important;
  gap: 1%;
}

/* Header */
.notificationsHeader {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 5%;
}

.notificationsHeaderOne {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* Section */
.notificationsSection,
.notificationsSectionModal {
  width: 100%;
  height: 62%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0 3%;
}

.notificationsSectionModal {
  height: 100% !important;
}
.notificationsContentHeaderModal {
  border: none !important;
  display: flex !important;
  justify-content: center !important;
  margin: 2% !important;

}

.notificationsContentHeaderModal .btn-close {
  color: var(--colorTwo) !important;
}

.notificationsContentModal {
  /* background-color: var(--primary-color) !important; */
  background: linear-gradient(135deg, #171717, #000000);
  color: var(--colorTwo) !important;
}

.notificationsSection .tabsMDB,
.notificationsSection .tabsMDBContent {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #0f0f0f75;
  border-radius: 10px;
  padding: 2% 0;
  margin: 0 0 16px 0 !important;
}

.notificationTitle {
  margin: 0 !important;
  font-size: xx-large !important;
  font-family: "IBM Plex Sans Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.notificationsSection .tabsMDB {
  width: max-content;
  padding: 2%;
}
.notificationViewAll {
  font-size: small;
  cursor: pointer;
  color: var(--colorTwo73);
}

.notificationsSection .tabsMDBContent {
  height: 100%;
}

/* List Group */
.listGroup {
  width: 100%;
  max-width: 100%;
  height: max-content;
  padding: 5% 0;
}

/* No Notifications */
.noNotificationsDiv,
.list-group-not {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: flex-start !important;
  gap: 15px;
  height: 100% !important;
  width: 100% !important;
  border-radius: 10px !important;
  text-align: center !important;
  padding: 5px 10px !important;
  margin: 0 !important;
  border: none !important;
}

.list-group-not .listGroupItem {
  border: none !important;
  margin: 0;
  padding: 0 !important;
  
}

.listGroupItem {
  background: none !important;
  border: none !important;
}

.yesNotificationDiv {
  background-color: transparent !important;
  width: 100%;

}

.noNotificationsDiv .blockquote-footer {
  text-align: center;
  color: var(--colorTwo73);
}

/* Footer */
.notificationsFooter {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  padding: 0 5%;
}

/* Divider */
.notificationsDivider {
  width: 100%;
  align-self: center;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 1px;
  margin: 10px 0;
}

.notificationIcon {
  width: 35px;
  height: 35px;
}


/* Divider vertical */
.notificationsDividerVertical {
  width: 1px;
  align-self: center;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgb(0, 0, 0) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 100%;
  margin: 0 10px;
}


/* Divider vertical */
.notificationsDividerVerticalW,
.notificationsDividerVerticalWChat {
  width: 1px;
  align-self: center;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    var(--colorTwo73) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 100%;
  margin: 0 10px;
}


.notificationsDividerVerticalWChat {
  margin: 0 !important;
}

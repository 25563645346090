.sidebar {
  height: 100%;
  width: max-content;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 10;
}

.NotificationDiv {
  width: 35%;
  height: 100%;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  align-self: flex-end;
  position: sticky;
  top: 0px;
  gap: 10px;
  border: 0.1px solid var(--colorTwo);
}
.NotificationDivSupport {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  align-self: flex-end;
  position: sticky;
  top: 0px;
  gap: 10px;
  border: 0.1px solid var(--colorTwo);
}

div.DashboardDivMaster,
.DashboardDiv {
  height: 100%;
  width: 70%;
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.AllProjectsContainer {
  height: 100%;
  width: 70%;
  overflow: auto;
  background-color: #ffffff;
  border: 0.5px solid rgba(48, 48, 48, 0.205);
  border-radius: 10px;
  padding: 1% 3% 1% 2%;
  display: flex;
  flex-direction: column;
}

.AllProjectsHeader {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.iconAvatar {
  color: var(--primary-color);
}
.AllProjectsSection {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AllProjectsSection .states {
  width: max-content;
  height: 100%;
}
.AllProjectsSection .states .mb-3 {
  margin: 0 !important;
}
.AllProjectsSection .filter {
  width: max-content;
  height: 100%;
  display: flex;
  gap: 2%;
}
.AllProjectsSection .filter .shadow-0 {
  width: max-content;
  height: 100%;
  background-color: var(--primary-color-card);
  color: var(--colorTwo);
  border: 0.1px solid #b9b9b967;
}
.AllProjectsBody {
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
}
.table-active {
  color: var(--primary-color-card) !important;
  background-color: var(--bg-color-form) !important;
}
.table-shut-down {
  color: var(--colorTwo) !important;
  background-color: var(--primary-color-card) !important;
}
.blockquote-footer {
  margin: 0 !important;
  text-align: start;
  size: 6px;
  text-size-adjust: 6px;
}

tr td,
tr th {
  text-align: center;
}

.sidebar-nav {
  /* height: 95%; */
  height: 100%;
  width: 80px;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color-card);
  border-right: 1px solid #3d3d3d;
  padding: 10px 0 25px 0;
  color: var(--colorTwo);
  /* border-radius: 35px; */
  border-radius: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.DashContainer {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: var(--bg-color-form);
  box-shadow: 0 0 25px 1px rgba(0, 0, 0, 0.1);
}

.chartContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 !important;
}

.statChart2 dl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1px;
  color: var(--colorTwo);
}
.statChart2.statChart2Pf dl {
  color: var(--primary-color-card) !important;
}
.chartContainerDiv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chartContainerTab {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding-bottom: 0;
  background-color: var(--bg-color-form);
  box-shadow: 0 0 25px 1px rgba(0, 0, 0, 0.1);
}
.chartContainerChart {
  width: 100%;
  height: 100%;
  border-radius: 15px 5px 5px 15px;
  padding: 2%;
  background-color: var(--bg-color-form);
  box-shadow: 0 0 25px 1px rgba(0, 0, 0, 0.1);
}

.pieChart {
  height: 100% !important;
}
.chartCard {
  width: 90%;
  height: 100%;
  border-radius: 15px;
  align-items: center;
  background-color: var(--bg-color-form);
  box-shadow: 0 0 25px 1px rgba(0, 0, 0, 0.1);
}
.chartCard.bottom {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  display: flex;
  align-items: center;
  background-color: var(--bg-color-form);
  box-shadow: 0 0 25px 1px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 0;
}
.chartCard.left {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  align-items: center;
  display: flex;
  gap: 10px;
  padding: 0;
  margin: 0;
  box-shadow: none;
  background: none;
}

.sidebar-header {
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-logo {
  overflow: hidden;
  text-wrap: nowrap;
  color: var(--colorTwo) !important;
  transition: all 0.3s ease;
}

.sidebar-logo.expanded {
  width: 128px;
  visibility: visible;
}

.sidebar-logo.collapsed {
  width: 0;
  visibility: hidden;
}

.sidebar-power {
  overflow: hidden;
  align-self: center;
  transition: all 0.3s ease;
}
.sidebar-power.expanded {
  width: max-content;
  visibility: visible;
}

.sidebar-power.collapsed {
  width: 0;
  visibility: hidden;
}

.toggle-button {
  padding: 6px;
  border-radius: 8px;
  background-color: #f9fafb;
  color: #2e2e2e;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #f3f4f6;
}

.sidebar-content {
  flex: 1;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-footer {
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
}
.sidebar-footer-container {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-area: 18px;
}

.sidebar-footer-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.profile-picture {
  width: 40px !important;
  height: 40px !important;
  border-radius: 8px !important;
  background-color: var(--bg-color-form) !important;
}

.profile-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  transition: all 0.3s ease;
}

.profile-info.expanded {
  width: 175px;
}

.profile-info.collapsed {
  width: 0;
}

.profile-details {
  line-height: 1.25;
}

.profile-name {
  font-weight: 600;
  text-wrap: nowrap;
}

.profile-email {
  font-size: 12px;
  color: #6b7280;
}

.sidebar-item {
  position: relative;
  height: 45px;
  width: max-content;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  margin: 4px 0;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.sidebar-item-avatar {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.sidebar-item-avatar:hover {
  background: none !important;
}

.sidebar-item:hover {
  background-color: var(--colorHover);
  color: var(--colorTwo);
}

.sidebar-item.active {
  background: var(--colorActive);
  height: 45px;
  color: var(--colorTwo);
}

.sidebar-item-text {
  overflow: hidden;
  text-wrap: nowrap;
  transition: all 0.3s ease;
}

.sidebar-item-text.expanded {
  width: 208px;
  margin-left: 12px;
}

.sidebar-item-text.collapsed {
  width: 0;
}

.alert-dot {
  position: absolute;
  right: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #4f46e5;
}

.tooltip {
  position: absolute;
  left: 130%;
  width: max-content;
  border-radius: 8px;
  padding: 4px 8px;
  margin-left: 24px;
  background-color: var(--primary-color);
  color: var(--colorTwo);
  font-size: 12px !important;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-12px);
  transition: all 0.3s ease;
}

.sidebar-item:hover .tooltip {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

/* ============================     chat     ================================= */

.chatContainer {
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.nav-link {
  background-color: #282828bd !important;
  color: #000121bd !important;
}
.nav-link.active {
  background-color: #000121bd !important;

  color: white !important;
}

.form-control {
  background: #282828bd !important;
  color: white !important;
  font-size: 1rem !important;
  line-height: 2.15 !important;
}
.form-control.amount {
  background: transparent !important;
  color: #ecdfcc !important;
  font-size: 1rem !important;
  line-height: 2.15 !important;
}

.form-control:focus {
  border: none !important;
}

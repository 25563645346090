.custom-container {
  width: 80%;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: var(--primary-color-card);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
  transform: scale(0.8); /* Scale down everything */
}

.custom-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.custom-client-name {
  font-size: 14px; /* Smaller text */
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: var(--colorTwo);
  cursor: pointer;
}

.custom-client-date {
  font-size: 10px; /* Smaller text */
  font-family: "Poppins", sans-serif;
  color: var(--colorTwo);
  display: block;
}

.custom-dropdown-toggle {
  background: none;
}

.custom-dropdown-icon {
  color: var(--colorTwo);
  font-size: 14px;
}

.custom-dropdown-item {
  display: flex;
  padding: 5px;
  gap: 5px;
  cursor: pointer;
  align-items: center;
}

.custom-textarea,
.custom-input {
  font-size: 12px; /* Smaller input fields */
}

.custom-btn {
  margin-top: 5px;
  font-size: 12px; /* Smaller buttons */
}

.custom-amount {
  font-size: 12px; /* Smaller amount display */
  color: var(--colorTwo);
}

.custom-popover-body {
  color: var(--colorTwo);
  background: #2e2e2e;
  border-radius: 5px;
}

.custom-show-more {
  cursor: pointer;
  color: #007bff;
  font-size: 12px; /* Smaller "Show More" text */
  margin-top: 5px;
  display: inline-block;
}

/* =====================================   project   =========================== */

/* Container styles */
.projectusers-container {
  width: 80%;
  padding: 20px 30px;
  border-radius: 15px;
  background-color: var(--primary-color-card);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Header styles */
.projectusers-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.projectusers-client-name {
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: var(--colorTwo);
  cursor: pointer;
}

.projectusers-client-date {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: var(--colorTwo73);
  display: block;
}

/* Dropdown styles */
.projectusers-dropdown-toggle {
  background: none !important;
}

.projectusers-dropdown-icon {
  color: var(--colorTwo);
  font-size: 18px;
}

.post-amount {
  width: 60%;
  display: flex;
  gap: 2%;
  align-items: center;
}

.projectusers-dropdown-item {
  display: flex;
  padding: 10px;
  gap: 10px;
  cursor: pointer;
  align-items: center;
}

/* Textarea and input styles */
.projectusers-textarea {
  font-size: 12px;
}

.projectusers-input {
  font-size: 12px;
}

/* Button styles */
.projectusers-btn {
  width: 100%;
  display: flex !important;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  font-size: 12px !important;
}

/* Body text styles */
.projectusers-body-text {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: small;
  line-height: 1.5;
  color: var(--colorTwo73);
  overflow: hidden;
  max-height: 4.5em;
  transition: all 0.5s ease-in-out;
}

.projectusers-subject-text {
  color: var(--colorTwo);
  font-size: medium;
}

.projectusers-body-text.expanded {
  max-height: max-content;
  transition: all 0.5s ease-in-out;
}

.projectusers-show-more {
  cursor: pointer;
  color: #007bff;
  font-size: 14px;
  margin-top: 10px;
  display: inline-block;
}

.projectusers-btn-group {
  display: flex;
  gap: 1%;
  justify-content: flex-end;
}

/* Footer styles */
.projectusers-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.projectusers-amount {
  font-size: 12px;
  color: var(--colorTwo);
}
.MDB-Popover-div {
  border: none !important;
}
.projectusers-popover-body {
  color: var(--colorTwo);
  background: linear-gradient(135deg, #171717, #000000);
  border: none !important;
  border-radius: 10px;
}

/* =====================================   projectList   =========================== */

/* Styles for the project list users container */
.projectlistusers-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5%;
  padding: 25px;
  overflow: auto;
}

/* Styles for the create project button container */
.projectlistusers-create-container {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 1%;
  width: 20% !important;
  z-index: 1;
  height: 18%;
  padding: 0 auto !important;
  border-radius: 15px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: stretch;
}

/* Styles for pagination navigation */
.projectlistusers-pagination-nav {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Styles for pagination component */
.projectlistusers-pagination {
  margin-bottom: 0;
  gap: 5%;
}

/* Styles for previous pagination item */
.projectlistusers-previous-item {
  /* Add any specific styling for previous item if needed */
}

/* Styles for number pagination items */
.projectlistusers-number-item {
  /* Add any specific styling for number items if needed */
}

/* Styles for next pagination item */
.projectlistusers-next-item {
  /* Add any specific styling for next item if needed */
}

/* Styles for no projects available message */
.projectlistusers-no-projects {
  text-align: center;
  color: var(--colorTwo);
}

/* Styles for the new project modal */
.projectnewmodal {
  /* Add any specific styling for the modal if needed */
}

/* Styles for the modal content */
.projectnewmodal-content {
  background: var(--primary-color-card);
  color: var(--colorTwo);
}

/* Styles for the modal header */
.projectnewmodal-header {
  border: none;
  display: flex;
  justify-content: center;
}

/* Styles for modal body */
.projectnewmodal-body {
  margin: 25px 0;
  border: none;
}

/* Styles for textarea item in modal */
.projectnewmodal-textarea-item {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

/* Styles for textarea in modal */
.projectnewmodal-textarea {
  font-size: 0.875rem; /* Adjust font size if needed */
}

/* Styles for textarea in modal */
.projectnewmodal-subject {
  font-size: 0.875rem; /* Adjust font size if needed */
}

/* Styles for input items in modal */
.projectnewmodal-input-item {
  /* Add any specific styling for input items if needed */
}

/* Styles for modal footer */
.projectnewmodal-footer {
  border: none !important;
}

/* Styles for buttons in modal */
.projectnewmodal-footer .btn {
  margin-top: 10px;
}

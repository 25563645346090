.chat-container {
  background-color: var(--primary-color-card);
  width: 100% !important;
  height: 100%;
  border-radius: 15px 15px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.chat-list-header {
  width: 100%;
  min-height: 10%;
  height: max-content;
  display: flex;
  justify-content: space-between;
  padding: 0 4%;
  align-items: center;
  color: var(--colorTwo);
  /* background-color: var(--primary-color); */
}
.chat-list-header.detail {
  justify-content: center;
}
.chat-list-header .h2DetailTitle {
  margin: 0 !important;
  font-size: xx-large !important;
  font-family: "IBM Plex Sans Condensed", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: var(--colorTwo);
}

.chat-list-header .chat-list-header-div {
  display: flex;
  gap: 10px;
  cursor: pointer;
  align-items: center;
}
.chat-list-header h6 {
  margin: 0;
  font-size: 14px;
  color: var(--colorTwo);
}
.chat-list-header p {
  margin: 0;
  font-size: 0.7rem;
  color: var(--colorTwo73);
}

.chat-list-item {
  cursor: pointer;
  background-color: var(--primary-color-s) !important;
  color: var(--colorTwo) !important;
  border-radius: 10px;
  padding: 8px 12px !important;
  transition: background-color 0.3s ease;
}

.chat-list-item.active {
  background-color: var(--primary-color) !important;
}

.avatar-container {
  flex-shrink: 0;
  height: 100%;
  display: flex;
  align-items: flex-start;
}

.avatar-img {
  border-radius: 10px !important;
  width: 45px !important;
  height: 45px !important;
  color: var(--colorTwo) !important;
}

.chat-details {
  overflow: hidden;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chat-item-title {
  font-weight: bold;
  margin: 0;
}

.chat-item-message,
.chat-item-time {
  color: #6c757d;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  text-overflow: ellipsis;
  font-size: 10px;
}

.chat-item-content {
  display: flex;
  align-items: center;
  padding: 10px;
}

.chat-details-time {
  display: flex;
  justify-content: space-between;
}
.unread-messages-indicator {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-layout {
  display: flex;
  height: 100%;
}

.chat-list {
  flex: 0.3;
  max-height: 100%;
  width: 30%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.chat-side-details {
  flex: 0.3;
  max-height: 100%;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: var(--colorTwo);
  background: linear-gradient(135deg, #171717, #000000);
}

.chat-list-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  gap: 10px;
  padding: 10px !important;
}
.chat-list-group .nt {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: transparent;
  color: #ffffff82;
  justify-content: center;
  align-items: center;
}

.chat-window .nt {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: var(--primary-color-s);
  color: var(--colorTwo73);
  justify-content: center;
  align-items: center;
}
.chat-side-details .nt {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: transparent;
  color: var(--colorTwo73);
  justify-content: center;
  align-items: center;
}

.chat-list-item:hover {
  background-color: var(--primary-color) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.chat-window {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex: 0.7;
  overflow: auto;
}
